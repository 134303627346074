import React from 'react'
import { Button, Modal, Form, Message } from 'semantic-ui-react'
import { useState } from "react";
import emailjs from '@emailjs/browser';

const initialFormValue = {
  name: '',
  email: '',
  nameOfCompany: '',
  enquiry: ''
}

const ContactModal = ({isOpenModal, setIsOpenModal}) => {
  const [formValue, setFormValue] = useState(initialFormValue);
  const [formState, setFormState] = useState('initial')
  const { name, email, nameOfCompany, enquiry } = formValue;

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormState('loading');
    setFormValue(initialFormValue);
    const formElm = document.getElementById('contactForm');
    emailjs.sendForm('service_59qunfn', 'template_nj2yfz4', formElm, '7c1TLHbKcQxJyAphZ')
      .then((result) => {
        setFormState('success');
      }, (error) => {
        console.log(error.text);
      });
  };

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  return (
    <div className="form_block">
      <Modal
        dimmer='blurring'
        size='small'
        closeOnDocumentClick={true}
        open={isOpenModal}
        onClose={() => {setIsOpenModal(false); setFormState('initial')}}
      >
        <Modal.Header>Contact with us</Modal.Header>
        <Modal.Content>
        <Form id='contactForm' onSubmit={(e)=> handleSubmit(e)} success={formState === 'success'} loading={formState === 'loading'} >
          <Form.Group widths='equal'>
            <Form.Input fluid label='Name' name="name" placeholder='Your name' value={name} onChange={(e) => handleChange(e)}/>
            <Form.Input fluid label='Email' name="email" placeholder='Your email' value={email} onChange={(e) => handleChange(e)}/>
          </Form.Group>
          <Form.Input fluid label='Name of company' name="nameOfCompany" placeholder='Your company' value={nameOfCompany} onChange={(e) => handleChange(e)}/>
          <Form.TextArea label='Enquiry' name="enquiry" value={enquiry} placeholder='Tell us more ...' onChange={(e) => handleChange(e)}/>
          <div>
            Japan is all about trust and reliability. This is something that cannot be bought, it must be earned.
          </div>
          <Message
            success
            header='Email sent 🎉'
            content="Thank you for reaching out 😄"
          />
          <Form.Button content='Submit' style={{display: 'none'}}/>
        </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button secondary type='submit' content='Submit' onClick={(e) => handleSubmit(e)}/>
        </Modal.Actions>
      </Modal>
    </div>
  )
}

export default ContactModal;