import './App.css';
import React, { Fragment, useState } from 'react'
import logoPath from"./logo.png"
import ContactModal from './Contact';

function App() {
  const [isOpenModal, setIsOpenModal] = useState(false)

  return (
    <Fragment>
      <div className="card_block">
        <div className="enter_card">
          <img src={logoPath} alt="enter_japan"/>
        </div>
        <div className="divider light"></div>
        <div className="divider small"></div>
        <button className='cta' onClick={() => setIsOpenModal(true)}>Let's talk</button>
      </div>

      <ContactModal isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal}></ContactModal>
    </Fragment>
  )
}

export default App;
